import { render, staticRenderFns } from "./PreviewPeriodBenefitTemplate.vue?vue&type=template&id=feef8356&scoped=true"
import script from "./PreviewPeriodBenefitTemplate.vue?vue&type=script&lang=js"
export * from "./PreviewPeriodBenefitTemplate.vue?vue&type=script&lang=js"
import style0 from "./PreviewPeriodBenefitTemplate.vue?vue&type=style&index=0&id=feef8356&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "feef8356",
  null
  
)

export default component.exports